import { Global } from '@emotion/react'
import { ThemeProvider } from '@emotion/react'
import { StytchProvider } from '@stytch/nextjs'
import { createStytchHeadlessClient } from '@stytch/nextjs/headless'
import 'highlight.js/styles/github.css'
import App, { AppContext } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { useState } from 'react'
import 'rsuite/dist/rsuite.min.css'

import ThemeContext from '@context/theme'
import { darkTheme, getSavedTheme, lightTheme, setSavedTheme } from '@lib/theme'
import '@static/fonts/encodeSans/encodeSans.css'
import '@static/fonts/inter/inter.css'

const stytch = createStytchHeadlessClient(process.env.STYTCH_PUBLIC_TOKEN || '')

const PortalApp = ({ Component, pageProps, isBotTraffic }: PortalAppProps) => {
  const [theme, setTheme] = useState(getSavedTheme())
  const themeMode = theme === 'light' ? lightTheme : darkTheme

  const setMode = (mode: string) => {
    window.localStorage.setItem('theme', mode)
    setTheme(mode)
    setSavedTheme(mode)
  }

  const toggleTheme = () => {
    theme === 'light' ? setMode('dark') : setMode('light')
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProvider theme={themeMode}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, viewport-fit=cover"
          />
        </Head>
        <Script
          id="isBotTraffic"
          dangerouslySetInnerHTML={{
            __html: `window.isBotTraffic = ${isBotTraffic};`,
          }}
        />
        {/* eslint-disable @next/next/no-before-interactive-script-outside-document */}
        <Script
          src={'https://js.stytch.com/stytch.js'}
          strategy="beforeInteractive"
        />
        {/* eslint-enable */}
        <StytchProvider stytch={stytch}>
          <Global
            styles={{
              body: {
                backgroundColor: themeMode.colors.white,
                color: themeMode.colors.text.body,
              },
              tspan: {
                color: themeMode.colors.text.axisLabel,
              },
            }}
          />
          <Component {...pageProps} />
        </StytchProvider>
        {!isBotTraffic && (
          <script
            dangerouslySetInnerHTML={{
              __html: `if (!window.isBotTraffic) {analytics.load('${process.env.SEGMENT_WRITEKEY}');}`,
            }}
          />
        )}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

PortalApp.getInitialProps = async (ctx: AppContext) => {
  const initialProps = await App.getInitialProps(ctx)
  const { req } = ctx.ctx

  return {
    ...initialProps,
    isBotTraffic:
      req?.headers?.['user-agent']?.includes('DatadogSynthetics') || false,
  }
}

export default PortalApp
